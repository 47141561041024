import {
  UserService as BaseUserService,
  LocalStorageService,
  LoggerService,
  UserAPIService,
  UserOptions,
  userOptionsDI,
} from '@framework';
import { Inject, Injectable } from '@angular/core';

import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseUserService {
  constructor(
    @Inject(userOptionsDI) opt: UserOptions,
    api: UserAPIService,
    localStorage: LocalStorageService,
    keycloak: KeycloakService,
    logger: LoggerService,
  ) {
    super(opt, api, localStorage, keycloak, logger);
  }

  override initApiKey(): void {
    this.logger.log('api key not needed for feedback app');
  }
}
