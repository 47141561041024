export const environment: any = { 
  isProductionEnv:false,
  environment:'dev',
  apiUriPrefix:'https://dev.direct.cz',
  keycloakUrl:'https://dev.direct.cz',
  ipexUrl:'//direct-test.servicedesk.net/Tickets/Show/',
  websocketUriPrefix:'wss://core-dev.direct.cz',
  localhost:false,
  publicDirectUrl:'https://dev.direct.cz',
}
