import { UserAccountService as BaseUserAccountService, UserAccount } from '@framework';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService extends BaseUserAccountService {
  override getById(_: number | String): Observable<UserAccount> {
    return of(null);
  }
}
