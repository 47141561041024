import { Component, inject } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'fb-app',
  templateUrl: './app.component.html',
})
export class AppComponent {
  private readonly router = inject(Router);

  handleNavigation(routerLink: string[]): void {
    this.router.navigate(routerLink);
  }

  clickLogo(): void {
    this.router.navigateByUrl('/');
  }
}
