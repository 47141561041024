import {
  ToastButtonsConfigurationService as AbstractToastButtonsConfigurationService,
  ToastButton,
  ToastMessage,
} from '@abstractions';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastButtonsConfigurationService extends AbstractToastButtonsConfigurationService {
  override getButtons(_: ((message: ToastMessage) => void)[]): Observable<ToastButton[]> {
    return of([]);
  }
}
