import { AccessDeniedComponent, NotFoundComponent } from '@features';

import { AuthGuard } from '@framework';
import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      roles: ['coreAccess-feedback'],
    },
    loadChildren: () => import('./feedback/feedback.module').then((m) => m.FeedbackModule),
  },
  {
    path: 'prihlaseni',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'permission-denied',
    component: AccessDeniedComponent,
    data: {
      noRedirect: true,
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      noRedirect: true,
    },
  },
];
